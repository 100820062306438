<template>
  <div>
    <div class="promocao-adicionar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Processamento Campanhas</h1>
          <FormularioCampanhas />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const FormularioCampanhas = () => import("@/components/elementos/paginas/FormularioCampanhas.vue");
export default {
  name: "TreinamentosAdicionar",
  components: {
    FormularioCampanhas,
  },
};
</script>

<style lang="scss">
.promocao-adicionar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
